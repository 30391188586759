import { Controller } from "stimulus";
import { getActiveCurrency } from "../../util/currency";
import { loadHighchartsWithHeatmap } from "../../util/load_package";
import { buildCategoryHeatmapConfig } from "../../template/charts/category_heatmap";
import { EventCurrencyChanged } from "../../events";
import { unconditionalTrackEvent } from "../../analytics";
import { VIEW_CATEGORY_STATS } from "../../mixpanel_config";

let Highcharts;

export default class extends Controller {
  // Value from data-attributes
  dataUrl = null;
  activeCurrency = null;
  heatmapType = null;

  // Inferred value, not from data-attributes
  heatmapInstance = null;
  heatmapLoaded = false;

  async connect() {
    Highcharts = await loadHighchartsWithHeatmap();

    this.lazyRefreshHeatmap();
    this.registerCurrencyChangeListener();
  }

  fetchData() {
    return fetch(this.dataUrl, { credentials: "same-origin" })
      .then(res => (res.ok ? res.json() : []))
      .catch(() => []);
  }

  lazyRefreshHeatmap() {
    const observer = new IntersectionObserver( (changes) => {
      for (const change of changes) {
        if (change.isIntersecting && !this.heatmapLoaded) {
          this.heatmapLoaded = true;
          this.refreshHeatmap();
          unconditionalTrackEvent(VIEW_CATEGORY_STATS);
          observer.disconnect();
        }
      }
    });

    observer.observe(this.element);
  }

  refreshHeatmap() {
    this.activeCurrency = getActiveCurrency();
    this.heatmapType = this.element.dataset.heatmapType;
    this.dataUrl = new URL(this.element.dataset.dataUrl);
    this.dataUrl.searchParams.set("vs_currency", this.activeCurrency);

    this.renderHeatmap();
  }

  renderHeatmap() {
    this.heatmapInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.fetchData().then(data => {
      const heatmapConfig = buildCategoryHeatmapConfig(data, this.element, this.activeCurrency, this.heatmapType);
      this.heatmapInstance =  Highcharts.chart(heatmapConfig);
    });
  }

  registerCurrencyChangeListener() {
    window.addEventListener(EventCurrencyChanged, (e) => {
      this.activeCurrency = e.detail.currencyCode;
      this.refreshHeatmap();
    });
  }

  // Listen for heatmap refresh dispatch event
  static values = { refresh: Boolean };
  refreshValueChanged() {
    if (!Highcharts || this.refreshValue === false) {
      return;
    }

    this.refreshValue = false;
    this.refreshHeatmap();
  }
}
