import { Controller } from "stimulus";
import { getActiveCurrency } from "../../util/currency";
import { loadHighcharts } from "../../util/load_package";
import { buildCategoryChartConfig } from "../../template/charts/category_chart";
import { EventCurrencyChanged } from "../../events";
import { memoizedFetcher } from "../../util/memoization";

let Highcharts;

export default class extends Controller {
  // Value from data-attributes
  vsCurrency = null;
  dataUrl = null;
  activeCurrency = null;
  chartType = null;

  // Inferred value, not from data-attributes
  chartInstance = null;
  chartLoaded = false;

  async connect() {
    Highcharts = await loadHighcharts();

    this.lazyRefreshChart();
    this.registerCurrencyChangeListener();
  }

  async fetchData() {
    return await Promise.all(
      this.dataUrls.map(async ([name, url]) => {
        let data = await memoizedFetcher.fetchWithCache(
          url, {}, 300000,
          () => { return []; }
        );
        data = (data === undefined || data.length === 0) ? [] : data.stats;

        return { name, data };
      })
    );
  }

  lazyRefreshChart() {
    const observer = new IntersectionObserver( (changes) => {
      for (const change of changes) {
        if (change.isIntersecting && !this.chartLoaded) {
          this.chartLoaded = true;
          this.refreshChart();
          observer.disconnect();
        }
      }
    });

    observer.observe(this.element);
  }

  refreshChart() {
    this.vsCurrency = this.element.dataset.vsCurrency;
    this.activeCurrency = getActiveCurrency();
    this.chartType = this.element.dataset.chartType;
    this.dataUrls = JSON.parse(this.element.dataset.dataUrls);
    this.dataGroupingDuration = this.element.dataset.dataGroupingDuration;

    // If vsCurrency is set, then this means the data URLs support multi-currency, eg: `/usd/market_cap_charts`
    if (this.vsCurrency) {
      this.dataUrls = this.dataUrls.map(([name, url]) => {
        url = url.replace(this.vsCurrency, this.activeCurrency);
        return [name, url];
      });
    }

    this.renderChart();
  }

  renderChart() {
    this.chartInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.fetchData().then(data => {
      const chartConfig = buildCategoryChartConfig(data, this.element, this.chartType, this.activeCurrency, this.dataGroupingDuration);
      this.chartInstance =  Highcharts.StockChart(this.element.id, chartConfig);
    });
  }

  registerCurrencyChangeListener() {
    window.addEventListener(EventCurrencyChanged, (e) => {
      this.activeCurrency = e.detail.currencyCode;
      this.refreshChart();
    });
  }

  // Listen for chart refresh dispatch event
  static values = { refresh: Boolean };
  refreshValueChanged() {
    if (!Highcharts || this.refreshValue === false) {
      return;
    }

    this.refreshValue = false;
    this.refreshChart();
  }
}
