import { Controller } from "stimulus";
import { getActiveCurrency } from "../../util/currency";
import { buildCategoryChartConfig } from "../../template/charts/category_chart";
import { EventCurrencyChanged, EventGeckoChartRendered } from "../../events";
import { loadHighcharts } from "../../util/load_package";
import { memoizedFetcher } from "../../util/memoization";

let Highcharts;

export default class extends Controller {
  // Provided values, from data attributes.
  vsCurrency = null;
  dataUrl = null;
  chart = "market_cap";

  // Inferred values, not from data attributes.
  chartInstance = null;
  activeCurrency = getActiveCurrency();
  listeningCurrencyChange = false;
  chartLoaded = false;

  async connect() {
    Highcharts = await loadHighcharts();

    this.lazyRefreshChart();
  }

  fetchData() {
    return memoizedFetcher.fetchWithCache(
      this.dataUrl, {}, 300000,
      () => { return []; }
    );
  }

  lazyRefreshChart() {
    const observer = new IntersectionObserver( (changes) => {
      for (const change of changes) {
        if (change.isIntersecting && !this.chartLoaded) {
          this.chartLoaded = true;
          this.refreshChart();
          observer.disconnect();
        }
      }
    });

    observer.observe(this.element);
  }

  refreshChart() {
    this.vsCurrency = this.element.dataset.vsCurrency;
    this.dataUrl = this.element.dataset.dataUrl;
    this.chartType = this.element.dataset.chartType;
    this.dataGroupingDuration = this.element.dataset.dataGroupingDuration;

    // If vsCurrency is set, then this means the data URLs support multi-currency, eg: `/usd/market_cap_charts`
    // Else, the data URL support multi-currency through parameters, eg: `market_cap_charts?vs_currency=usd`
    if (this.vsCurrency) {
      this.dataUrl = this.dataUrl.replace(this.vsCurrency, this.activeCurrency);
    } else {
      let dataUrl = new URL(this.dataUrl)
      dataUrl.searchParams.set("vs_currency", this.activeCurrency);
      this.dataUrl = dataUrl;
    }

    this.renderChart();
  }

  renderChart() {
    this.chartInstance?.showLoading("<i class='far fa-fw fa-spinner-third fa-spin tw-text-2xl'></i>");

    this.fetchData().then(data => {
      const chartConfig = buildCategoryChartConfig(
        data,
        this.element,
        this.chartType,
        this.activeCurrency,
        this.dataGroupingDuration,
      );

      this.chartInstance = Highcharts.StockChart(this.element.id, chartConfig);

      // Inform other controllers that the chart was rendered recently.
      this.element.dispatchEvent(new CustomEvent(EventGeckoChartRendered, {
        bubbles: true,
        detail: { type: "category", chart: this.chartInstance }
      }));

      // Rerender chart on currency change event, register event once only.
      if (!this.listeningCurrencyChange) {
        this.listeningCurrencyChange = true;
        window.addEventListener(EventCurrencyChanged, (e) => {
          this.activeCurrency = e.detail.currencyCode;
          this.refreshChart();
        });
      }
    });
  }

  // Watch for re-renders with the "data-category-chart-rerender-value" attribute.
  // Enables triggering a re-render from other controllers by changing the attribute value.
  // https://stimulus.hotwired.dev/reference/values#change-callbacks
  static values = { rerender: Boolean };
  rerenderValueChanged() {
    if (!Highcharts || this.rerenderValue === false) {
      return;
    }

    this.rerenderValue = false;
    this.refreshChart();
  }
}
