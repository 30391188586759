import { formatCurrency, getActiveCurrency } from "../../util/currency";
import { buildChartDefs, drawWatermark } from "./generic_chart";

const _1_HOUR_IN_MS = 1 * 60 * 60 * 1000;
const _1_THOUSAND = 1000;

const EXPONENTS_CONFIG = {
  maximumDecimalTrailingZeroes: 1,
  significantFigures: 4,
};

const formatterDateTime = new Intl.DateTimeFormat(I18n.locale, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hourCycle: 'h23',
  timeZoneName: 'short',
});

// reference: highcharts.scss > *[data-controller="nft-global-stats"] .highcharts-color-{n}
const highchartColors = [
  "#58232C",
  "#DC5B7B",
  "#963487",
  "#123289",
  "#57ADDF",
  "#0C584D",
  "#9FD650",
  "#FBE669",
  "#F09D4E",
  "#C75931",
];

export const buildCategoryChartConfig = (data, element, chartType, currencyCode = getActiveCurrency(), dataGroupingDuration = null) => {
  const { seriesType, formatter } = buildSeriesTypeAndFormatter(chartType, currencyCode);

  return {
    legend: { enabled: true },
    tooltip: {
      useHTML: true,
      outside: true,
      borderRadius: 16,
      padding: 0.5,
      formatter() {
        let content = `<div class="title">${formatterDateTime.format(this.x)}</div>`;

        // Loop through all y-axes and display in tooltip.
        this.points.forEach((point) => {
          let value;

          switch(chartType) {
            case "market_cap":
              value = formatCurrency(point.y, currencyCode);
              break;
            case "dominance":
              value = `${point.y.toFixed(2)}%`;
              break;
            case "dominance_auto":
              value = `${point.percentage.toFixed(2)}%`;
              break;
            case "performance":
              let change = point.point.change ? point.point.change.toFixed(2) : 0;

              // Do not use abbreviation when value is less than or equal to 0.001, otherwise will show as 0
              if (point.y < 0.001) {
                value = `${formatCurrency(point.y, currencyCode)} (${change}%)`;
              } else {
                value = `${formatCurrency(point.y, currencyCode, false, true, true)} (${change}%)`;
              }

              break;
            default:
              value = formatCurrency(point.y, currencyCode);
          }

          content += `<div><span style="color:${highchartColors[point.series.colorIndex]}"">\u25CF</span> ${point.series.name}: <span class="value">${value}</span></div>`;
        });

        return content;
      }
    },

    xAxis: {
      minRange: _1_HOUR_IN_MS,
    },
    yAxis: buildCategoryChartAxes(formatter),
    series: buildCategoryChartSeries(data, seriesType, dataGroupingDuration),

    exporting: false,
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    credits: { enabled: false },

    plotOptions: {
      area: {
        stacking: "percent",
        lineWidth: 2,
        fillOpacity: 0.05,
        marker: {
          enabled: false
        }
      },
      series: {
        dataGrouping: buildDataGrouping(dataGroupingDuration),
        ...buildPlotOptionsSeries(chartType),
      }
    },

    chart: {
      events: { render: (e) => drawWatermark(e, true) },
      panning: false,
      marginTop: 50,
      renderTo: element.id,
      styledMode: true,
      zoomType: 'x',
    },

    time: { useUTC: true },

    ...buildChartDefs(),
  };
};

export const buildCategoryChartSeries = (data, seriesType, dataGroupingDuration) => {
  return data.map((data, index) => {
    return {
      name: data.name,
      type: seriesType,
      data: data.data,
      colorIndex: index % 10,
      threshold: -Infinity,
      maxPointWidth: 250,
      boostThreshold: undefined,
      lineWidth: 1,
      marker: {
        enabled: false,
        radius: 0
      },
      states: {
        hover: {
          lineWidth: 1
        }
      },
    };
  });
};

export const buildCategoryChartAxes = (formatter) => {
  return {
    title: {
      text: null
    },
    showLastLabel: true,
    opposite: true,
    labels: {
      align: "left",
      reserveSpace: true,
      useHTML: true,
      formatter: formatter
    },
  };
};

export const buildSeriesTypeAndFormatter = (chartType, currencyCode) => {
  const config = {
    market_cap: {
      seriesType: "line",
      formatter: currencyFormatter(currencyCode),
    },
    dominance: {
      seriesType: "area",
      formatter: percentageFormatter(),
    },
    dominance_auto: {
      seriesType: "area",
      formatter: percentageFormatter(),
    },
    performance: {
      seriesType: "line",
      formatter: percentageFormatter(),
    },
  };

  return config[chartType] || {
    seriesType: "line",
    formatter: currencyFormatter(currencyCode),
  };
}

export const currencyFormatter = (currencyCode) => {
  return function() {
    return formatCurrency(this.value, currencyCode, false, this.value < 1 ? EXPONENTS_CONFIG : false, this.value >= _1_THOUSAND);
  };
}

export const percentageFormatter = () => {
  return function() {
    return `${this.value.toFixed(2)}%`;
  };
}

export const buildPlotOptionsSeries = (chartType) => {
  const config = {
    market_cap: {
      animation: false
    },
    dominance: {
      animation: false
    },
    dominance_auto: {
      stacking: "percent",
      animation: false
    },
    performance: {
      compare: "percent",
      compareStart: true
    },
  };

  return config[chartType] || {
    animation: false
  };
}

export const buildDataGrouping = (dataGroupingDuration) => {
  const defaultConfig = { enabled: false };

  if (dataGroupingDuration === null || dataGroupingDuration === undefined) {
    return defaultConfig;
  }

  const dataGroupingDurationsConfig = {
    "h24": [["minute", [10]]],
    "d7": [["hour", [1]]],
    "d30": [["hour", [1]]],
    "m1": [["hour", [1]]],
    "d90": [["hour", [3]]],
    "m3": [["hour", [3]]],
    "ytd": [["hour", [3]]],
    "d365": [["day", [1]]],
    "y1": [["day", [1]]],
    "max": [["day", [1]]]
  };

  return {
    enabled: true,
    forced: true,
    groupAll: true,
    units: dataGroupingDurationsConfig[dataGroupingDuration]
  };
}
